import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlay, faX } from '@fortawesome/free-solid-svg-icons';
import './welcomeModal.css';

function WelcomeModal({ onClose, language }) {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-button-container">
          <div className="header-logo-container">
            <svg xmlns="http://www.w3.org/2000/svg" className="scf-logo" viewBox="0 0 100 100">
              <path d="m88.43 81.83-33.3-66.62a1.5 1.5 0 0 0-2.68 0L43.9 32.29 32 8.5a1.5 1.5 0 0 0-2.68 0L18.14 30.86 2.66 61.83A1.5 1.5 0 0 0 4 64h24l-8.86 17.83A1.5 1.5 0 0 0 20.48 84h66.6a1.5 1.5 0 0 0 1.34-2.17Zm-34.65-62.6 14.7 29.42c-5.95 2.4-9 .62-12.55-1.46C53.4 45.69 50.52 44 46.5 44a13.44 13.44 0 0 0-5.7 1.21Zm-23.11-6.71L39 29.24c-3.29 1.3-4.83.37-6.76-.8a9.51 9.51 0 0 0-5.16-1.79 10.6 10.6 0 0 0-3.92.82ZM6.43 61l14.28-28.56c.75-.62 3.54-2.78 6.4-2.78a6.82 6.82 0 0 1 3.6 1.34c2.15 1.3 4.82 2.91 9.66.92l1.86 3.71L29.55 61ZM22.9 81l8.81-17.63a1.49 1.49 0 0 0 .2-.4l4.85-9.7a1.45 1.45 0 0 0 .11-.17A10.69 10.69 0 0 1 46.5 47c3.2 0 5.49 1.35 7.92 2.77 3.73 2.19 8 4.67 15.42 1.57L84.66 81Z" fill="#0082ba" />
            </svg>
            <h4>Swiss AI Chatbot Factory</h4>
          </div>
          <button className="close-button" onClick={onClose}>
            <div className="option-content">
              <FontAwesomeIcon icon={faX} className="close-icon" />
            </div>
          </button>
        </div>
        <p>Here you can:</p>
        <div className="checklist">
          <li>
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            Ask your chatbot any question and get instant responses.
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            Test how your chatbot handles customer queries.
          </li>
          <li>
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            Forward requests to your email address.
          </li>
        </div>
        <div className="options">
          <button className="modal-option-button" onClick={onClose}>
            <div className="option-content">
              <FontAwesomeIcon icon={faPlay} className="agree-icon" />
              Test Bot
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default WelcomeModal;
